
import {
  AnalyticsTypes,
  AnalyticsState,
  DashboardFilter,
} from "@/store/modules/analytics/analytics.types";
import { FilterType, FilterValue } from "@/types/types";
import VueApexCharts from "vue-apexcharts";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Analytics = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class TicketStats extends Vue {
  @Analytics.State(AnalyticsTypes.DASHBOARD_STATUS)
  public dashboardStatus!: AnalyticsState;

  @Analytics.Getter(AnalyticsTypes.CURRENT_DASHBOARD_FILTER)
  public currentDashboardFilter!: FilterType;

  @Analytics.Action(AnalyticsTypes.LOAD_DASHBOARD_STATUS)
  public loadDashboardStats!: (filter: DashboardFilter) => void;

  get series(): unknown {
    return [
      {
        name: "Tickets",
        data: [
          {
            x: "New",
            y: this.getByStatus("New").toString(),
            color: "primary",
          },
          {
            x: "InProgress",
            y: this.getByStatus("InProgress").toString(),
            color: "#FFB400",
          },
          {
            x: "Resolved",
            y: this.getByStatus("Resolved").toString(),
            color: "#57C900",
          },
          {
            x: "Closed",
            y: this.getByStatus("Closed").toString(),
            color: "#13B1FE",
          },
        ],
      },
    ];
  }

  getByStatus(status: string): number {
    return (
      this.dashboardStatus.analytics.find((x) => x.name == status)?.count || 0
    );
  }

  get options(): unknown {
    return {
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "20px",
        },
      },
      colors: [
        function ({ dataPointIndex }: { dataPointIndex: number }) {
          switch (dataPointIndex) {
            case 0:
              return "#A16EFE";
            case 1:
              return "#FFB400";
            case 2:
              return "#57C900";
            case 3:
              return "#13B1FE";
            default:
              return "#F5F5F9";
          }
        },
      ],
      grid: {
        show: true,
        borderColor: "#EEEEEE",
        strokeDashArray: 10,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: ["#757575"],
          },
          formatter: (value: number) => value.toFixed(0),
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
    };
  }

  public filterValue: FilterValue = {};

  filterChange(value: FilterValue): void {
    this.filterValue = value;

    this.loadDashboardStats({
      filterBy: value.filterValue?.value,
      force: value.forced,
    });
  }

  get period(): string {
    return this.currentDashboardFilter.title;
  }

  mounted(): void {
    this.filterValue.filterValue = this.currentDashboardFilter;
    this.filterChange(this.filterValue);
  }
}
